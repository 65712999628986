@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/dt-base';

%product-image-size {
  min-width: 130px;
  min-height: 100%;
}

.container {
  @extend %product-image-size;
  position: relative;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.37s;
}

.container--loaded {
  opacity: 1;
}

.image-block {
  @extend %product-image-size;
  display: block;
  margin-top: 10px;
  background-repeat: no-repeat;
  background-position-x: calc(50% - 10px);
}

.wheel-image-block {
  @extend .image-block;
  background-position-x: calc(50% - 40px);
}

.services-image-block {
  @extend .image-block;
  background-size: auto 70px;
  background-position: center;
  margin-top: 0;
}

.accessory-image-block {
  @extend .image-block;
  background-size: 150px;
  background-position-x: calc(50% - 20px);
}

.logo {
  max-width: 90%;
  height: auto;
  margin: 5px auto 0;
  background: no-repeat center;
}

.gbb {
  position: absolute;
  left: 0;
  bottom: 0;
}
