@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../../shared/scss/dt-base';

.nav-button {
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px 10px;
  background: transparent;
  color: $dark-gray;
  font-weight: 700;

  font-size: rem(14);
  white-space: nowrap;

  @include media($min-sm) {
    font-weight: 600;
    font-size: rem(13);
    text-transform: none;
  }

  @include media($min-lg) {
    padding: 12px;
  }
  & > * {
    pointer-events: none;
  }
}

.nav-button--separator::before {
  @include media($min-sm) {
    content: '';
    position: absolute;
    left: 0;
    height: 22px;
    width: 1px;
    background: $dt-nav-light-gray;
  }
}

.my-vehicle {
  margin-left: 7px;
  max-width: 12vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;

  @include media($min-md) {
    max-width: 17vw;
  }
}

.car-icon {
  font-size: rem(16);
}
