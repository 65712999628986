@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../shared/scss/_dt-base';

.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

%container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px -10px 0;
  width: calc(100% + 10px);
  max-width: 335px;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  min-width: 165px;
  border: 0 solid transparent;

  > * {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}

.horizontal {
  @extend %container;

  .cell {
    border-width: 0 12px 12px 0;
  }

  @include media($min-sm) {
    max-width: 685px;
  }

  .cell {
    @include media($min-sm) {
      width: 50%;
    }

    &:last-child:nth-child(odd) {
      width: 100%;
    }
  }
}

.vertical {
  @extend %container;

  .cell {
    border-width: 0 0 12px 0;
  }
}
