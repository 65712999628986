@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../../shared/scss/dt-base';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cec-user-icon {
  color: $cec-dark-red;
}

.button-container.button-container {
  &::before {
    content: unset;
  }
  max-width: 200px;
  height: 20px;
  border-right: 1px solid $cec-medium-gray;
  color: $tw-gray-02;
  padding: 15px 15px 0 15px;
  display: flex;
  align-items: center;
  position: relative;
  @include media($min-sm) {
    padding: 10px;
  }
}

.button-container--light-border.button-container--light-border {
  border-right: 1px solid $cec-medium-light-gray;
}

.button-container--no-border.button-container--no-border {
  border-right: 0;
}

.button-text {
  margin-left: 7px;
  margin-right: 7px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: rem(12);
  color: $dt-black;
}

.button-text--csr-name {
  max-width: 80px;
}

.popover {
  padding: 29px 21px 18px;
  display: flex;
  flex-direction: column;
  @include media($min-sm) {
    min-width: 320px;
  }
  :global .button {
    text-align: left;
  }
}

.popover-subtitle {
  color: $cec-medium-light-gray-02;
  font-size: rem(16);
  margin-top: -7px;
  margin-bottom: 6px;
}

.popover-subtitle--less-width {
  max-width: 320px;
  color: $black;
}

.popover-footer {
  display: flex;
  justify-content: space-between;
  margin: 16px -21px 0;
  border-top: 1px solid $cec-light-gray;
  padding: 14px 21px 0;
}

.link {
  padding: 0;
  font-size: rem(14);
}

.popover-container {
  border: 1px solid $grey-500;

  :global {
    .popover__pointer {
      border: 1px solid $grey-500;
      background: $white;
    }
    .popover__child-wrapper {
      background: $white;
    }
  }
}
